import styled from 'styled-components';
import { bw } from 'colors';
import { desktopOnly, mobileOnly, mobilePortraitOnly } from 'style/mixins';
import {
  ACCENT_PRIMARY,
  SURFACE_3,
  ADMIN_ALERT_ERROR,
  TEXT_100,
  TEXT_CONTRAST_ON_ACCENT_PRIMARY,
  FONT_FAMILY_BODY,
} from 'style/constants';
import Icon from 'components/ui/Icon';
import DateAndTime from 'components/admin2/AccessGate/SharedComponents/DateAndTime';
import GateTextSection from 'components/admin2/AccessGate/SharedComponents/GateTextSection';
import SiteThemeButton from 'components/ui/Button';
import { TEXT_BODY_S_BOLD } from 'style/design-system/textStyles';

export const Container = styled.div`
  font-family: ${FONT_FAMILY_BODY};
  width: 100%;
  word-break: break-word;
  ${desktopOnly`padding: 40px;`};
  ${mobileOnly`padding: 30px;`};
`;

export const StyledDateAndTime = styled(DateAndTime)`
  margin-bottom: 30px;
  width: fit-content;
`;

export const Label = styled.span`
  color: ${TEXT_100};
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  span {
    color: ${ADMIN_ALERT_ERROR};
    font-style: normal;
    font-weight: 400;
  }
`;

export const Form = styled.form``;

const AllSubscriptionTypes = styled.div`
  display: flex;
  flex-direction: column;

  padding: 5px;
  ${mobilePortraitOnly`padding: 0;`};
`;

export const TicketsAndSubs = styled(AllSubscriptionTypes)``;
export const Bundles = styled(AllSubscriptionTypes)``;

export const Button = styled(SiteThemeButton)<{ hasError: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 121px;
  height: 40px;
  border-radius: 5px;
  margin-top: ${(props) => (props.hasError ? '15px' : '10px')};
  margin-bottom: 15px;
  ${TEXT_BODY_S_BOLD}
  text-transform: uppercase;
`;

export const StyledSpan = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${ACCENT_PRIMARY};
`;

export const OrContinueText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  color: ${TEXT_100};
  margin-top: 10px;
`;
