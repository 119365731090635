import React from 'react';
import ContentLayout from 'components/regions/ContentLayout';
import LandingPageGrid from 'components/regions/LandingPageGrid';
import { useDispatch, useSelector } from 'react-redux';
import { getVodIdFromQuery } from 'services/video';
import { isDesktopLayout, isLandscape } from 'services/device';
import { shouldRenderChannelGate } from 'services/gate';
import { setOrientationLocked } from 'services/user-layout/actions';
import { getIsOrientationLocked } from 'services/user-layout/selectors/common';

const LandingPage = () => {
  const isOrientationLocked = useSelector(getIsOrientationLocked);
  const isChannelGateOn = useSelector(shouldRenderChannelGate);
  const vodQueryParam = Boolean(useSelector(getVodIdFromQuery));
  const isDesktop = useSelector(isDesktopLayout);
  const isLandscapeLayout = useSelector(isLandscape);
  const dispatch = useDispatch();

  const showLandingPageGrid = React.useMemo(() => {
    // mobile portrait view beefy grid is rendered in MobileBody
    // mobile landscape beefy grid is rendered in BeefyGridRegion
    if (vodQueryParam || isChannelGateOn || (!isDesktop && !isLandscapeLayout)) {
      return false;
    }
    return true;
  }, [isDesktop, isLandscapeLayout, vodQueryParam, isChannelGateOn]);

  if (!isOrientationLocked) {
    dispatch(setOrientationLocked(true));
  }

  return (
    <ContentLayout>
      {showLandingPageGrid && <LandingPageGrid />}
    </ContentLayout>
  );
};

export default LandingPage;
