import React, {
  useState,
  useCallback,
  useEffect,
  FormEventHandler,
} from 'react';
import { isValidEmail } from 'utils';
import ExternalLoginButton from '../ExternalLoginButton';
import { TITLE, SUBTITLE, BUTTON } from 'injection-classes';
import {
  FormBody,
  MaestroLoginContainer,
  ModalTitle,
  ModalDescription,
  FormSubmit,
  RegisterLoginSpinnerContainer,
  RegisterLoginSpinner,
  OrText,
  ExternalAuthsWrapper,
} from '../commonStyles';
import { DynamicTranslationType, useEndUserTranslation } from 'hooks/use-translation';
import FormInput from 'components/ui/v2/Inputs';

interface IAccountFound {
  email: string;
  isAccountFound: boolean;
  userName: string;
}

interface IAccountLookupView {
  accountFound?: IAccountFound | null;
  enableMaestroLogin: boolean;
  id: string;
  onAccountLookup: (email: string) => void;
  setPage: (page: string) => void;
  validAuths: string[];
}

const AccountLookupView: React.FC<IAccountLookupView> = ({
  accountFound = null,
  enableMaestroLogin,
  id,
  onAccountLookup,
  setPage,
  validAuths,
}) => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const { endUserT } = useEndUserTranslation();

  const emailNotValidErrorMsg = endUserT(
      [DynamicTranslationType.globalValidationInvalidEmail],
      ['EMAIL_IS_NOT_VALID'],
  );

  const redirectToModal = useCallback(
      (isAccountFound: boolean) => {
        if (isAccountFound) {
          setPage('LOGIN');
        } else {
          setPage('SIGNUP');
        }
      },
      [setPage],
  );

  useEffect(() => {
    if (accountFound) {
      setIsLoading(false);
      redirectToModal(accountFound.isAccountFound);
    }
  }, [accountFound, redirectToModal]);

  const checkEmail = useCallback(
    (emailToCheck: string) => {
      if (emailToCheck === '') return;

      if (!isValidEmail(emailToCheck)) {
        setError(emailNotValidErrorMsg);
        return;
      }
      setError('');
    },
    [emailNotValidErrorMsg],
  );

  const onBlur = useCallback(() => {
    checkEmail(email);
  }, [email, checkEmail]);

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();

      if (isValidEmail(email)) {
        setIsLoading(true);
        onAccountLookup(email.toLowerCase());
        return;
      }

      setError(emailNotValidErrorMsg);
    },
    [email, onAccountLookup, emailNotValidErrorMsg],
  );

  const renderMaestroLogin = () => {
    if (!enableMaestroLogin) {
      return null;
    }

    return (
      <MaestroLoginContainer>
        <ModalTitle className={TITLE} data-testid="authModalAccountLookupTitle">
          {endUserT([DynamicTranslationType.accountLookupTitle], ['ENTER_YOUR_EMAIL'])}
        </ModalTitle>
        <ModalDescription className={SUBTITLE} data-testid="authModalAccountLookupDescription">
          {endUserT([DynamicTranslationType.accountLookupDescription], ['ACCOUNT_LOOKUP_MODAL_DESCRIPTION'])}
        </ModalDescription>
        <FormInput.Root error={!!error}>
          <FormInput.FieldSet>
            <FormInput.Legend>{endUserT([DynamicTranslationType.accountLookupTitle], ['EMAIL'])}</FormInput.Legend>
            <FormInput.InputIcon name="mail" />
            <FormInput.TextInput
              data-testid="authModalAccountLookupEmailInput"
              autoFocus={true}
              name="email"
              onBlur={onBlur}
              onChange={setEmail}
              placeholder={endUserT(
                [DynamicTranslationType.globalFormLabelEnterEmailAddr],
                ['ENTER_YOUR_EMAIL_ADDRESS'],
              )}
              type="email"
              value={email}
            />
          </FormInput.FieldSet>
          {error && <FormInput.SupportingText>{error}</FormInput.SupportingText>}
        </FormInput.Root>
        <FormSubmit
          className={BUTTON}
          data-testid="authModalAccountLookupSubmitButton"
          type={!isLoading ? 'submit' : 'button'}
        >
          {!isLoading ? endUserT([DynamicTranslationType.globalActionNext], ['NEXT']) : (
            <RegisterLoginSpinnerContainer>
              <RegisterLoginSpinner />
            </RegisterLoginSpinnerContainer>
          )}
        </FormSubmit>
        {validAuths.length > 1 && (
          <OrText>
            {endUserT(
                [DynamicTranslationType.globalFormLabelOr],
                ['OR'],
            )}
          </OrText>
        )}
      </MaestroLoginContainer>
    );
  };

  const renderExternalAuthsLabel = () => {
    const shouldRender = !validAuths.includes('maestro') && validAuths.length > 0;
    if (!shouldRender) {
      return null;
    }

    return (
      <>
        <ModalTitle data-testid="authModalAccountLookupNoMaestroAuthTitle">
          {endUserT([DynamicTranslationType.accountLookupNoMaestroAuthTitle], ['LOG_IN_TO_GAIN_ACCESS'])}
        </ModalTitle>
        <ModalDescription data-testid="authModalAccountLookupNoMaestroAuthDescription">
          {endUserT([DynamicTranslationType.accountLookupNoMaestroAuthDescription], ['LOG_IN_TO_GAIN_ACCESS_DESCRIPTION'])}
        </ModalDescription>
      </>
    );
  };

  return (
    <FormBody id={id} onSubmit={onSubmit}>
      {renderMaestroLogin()}
      {renderExternalAuthsLabel()}
      <ExternalAuthsWrapper data-testid="authModalExternalAuthsWrapper">
        {validAuths.map((kind) => <ExternalLoginButton key={kind} kind={kind} signUp={false} />)}
      </ExternalAuthsWrapper>
    </FormBody>
  );
};

export default AccountLookupView;
