/* eslint react/destructuring-assignment: off */
import PropTypes from 'prop-types';
import React from 'react';
import { FORCE_ENABLE_MAESTRO_LOGIN } from 'config';
import { AUTH_MODAL_ID, CREATE_ACCOUNT_ID, ENTER_EMAIL_ID, ENTER_PASSWORD_ID } from 'global-ids';
import { MODAL_BACKGROUND } from 'injection-classes';
import { OauthProvider } from 'services/auth';
import { RegisterView } from './RegisterView';
import LoginView from './LoginView';
import AccountLookupView from './AccountLookupView';
import {
  StyledCloseButton,
  StyledModalWindow,
  ContentContainer,
  PageWrapper,
} from './commonStyles';
import SelectSiteModal from './SelectGlobalAccountSitesView';

const toAuthsArray = (authsObject) => Object.keys(authsObject).filter((key) => authsObject[key]);

export default class AuthModal extends React.Component {
  static propTypes = {
    accountFound: PropTypes.shape({
      email: PropTypes.string,
      isAccountFound: PropTypes.bool,
      userName: PropTypes.string,
    }),
    auths: PropTypes.objectOf(PropTypes.bool),
    cleanGlobalAccount: PropTypes.func.isRequired,
    clearAccountLookup: PropTypes.func.isRequired,
    customModal: PropTypes.shape({
      privacy: PropTypes.string.isRequired,
      terms: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.shape({
      email: PropTypes.string,
      page: PropTypes.string.isRequired,
      provider: PropTypes.string,
    }),
    dismissError: PropTypes.func.isRequired,
    dismissOverride: PropTypes.func,
    enableMaestroLogin: PropTypes.bool.isRequired,
    globalAccountAdminSites: PropTypes.arrayOf(PropTypes.object).isRequired,
    loggedIn: PropTypes.bool.isRequired,
    loginFailed: PropTypes.bool.isRequired,
    marketingOptin: PropTypes.bool,
    marketingOptinLabel: PropTypes.string,
    onAccountLookup: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
    onEmailLogin: PropTypes.func.isRequired,
    onForgotPassword: PropTypes.func.isRequired,
    onOauthLogin: PropTypes.func.isRequired,
    onRegister: PropTypes.func.isRequired,
    registerError: PropTypes.string.isRequired,
    style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    accountFound: null,
    auths: {},
    data: undefined,
    dismissOverride: undefined,
    marketingOptin: false,
    marketingOptinLabel: '',
    style: {},
  };

  state = {
    email: this.props.data?.email || '',
    page: this.props.data?.page || '',
    triggeredOauthLogin: false,
  };

  componentDidMount() {
    this.closeIfLoggedIn(false);
    this.bypassIfSingleOauthProvider();
  }

  componentDidUpdate(prevProps) {
    this.closeIfLoggedIn(prevProps.loggedIn);
    this.bypassIfSingleOauthProvider();

    if (this.props.globalAccountAdminSites.length > 0 && this.state.page !== 'GLOBAL_ACCOUNTS_SELECT_SITE') {
      this.setPage('GLOBAL_ACCOUNTS_SELECT_SITE');
    }
  }

  componentWillUnmount() {
    this.props.cleanGlobalAccount();
    this.props.clearAccountLookup();
    this.props.dismissError();
  }

  dismissModal() {
    const { dismissOverride, onDismiss } = this.props;
    if (dismissOverride) {
      dismissOverride();
    } else {
      onDismiss();
    }
  }

  bypassIfSingleOauthProvider() {
    const { auths, onOauthLogin } = this.props;
    const { triggeredOauthLogin } = this.state;
    if (triggeredOauthLogin || FORCE_ENABLE_MAESTRO_LOGIN) {
      return;
    }
    const authList = Object.entries(auths)
      .filter(([, enabled]) => enabled)
      .map(([name]) => name);
    if (authList.length === 1) {
      const provider = authList[0];
      // we're only going to opt-in for battlenet
      // because this will force sites with single third-party auths
      // to go through the new auth system and may break if they haven't been
      // implemented fully
      if (provider !== OauthProvider.BATTLENET) {
        return;
      }
      const isValidOauthProvider = Object.values(OauthProvider).includes(
        provider,
      );
      if (isValidOauthProvider) {
        this.setState({ triggeredOauthLogin: true });
        onOauthLogin(provider);
        this.dismissModal();
      }
    }
  }

  closeIfLoggedIn(prevLoggedIn) {
    if (!prevLoggedIn && this.props.loggedIn) {
      this.dismissModal();
    }
  }

  onLogin = (email, password) => {
    const { onEmailLogin, data } = this.props;
    const subscriptionCheckoutData = data?.checkoutData;
    const provider = data?.provider;
    onEmailLogin({
      email,
      password,
      provider,
      subscriptionCheckoutData,
    });
  };

  onAccountLookup = (email) => {
    const { onAccountLookup } = this.props;
    onAccountLookup({ email });
    this.setState({ email });
  };

  onRegister = (email, name, password, marketingOptin) => {
    // TODO: handle mismatched passwords
    // TODO: fix subscription signup
    const { onRegister, data } = this.props;
    const subscriptionCheckoutData = data?.checkoutData;
    const provider = data?.provider;
    onRegister({
      email,
      marketingOptin,
      name,
      password,
      provider,
      subscriptionCheckoutData,
    });
  };

  setPage = (page) => {
    this.setState({ page });
    this.props.dismissError();
  };

  onClose = () => {
    const { clearAccountLookup } = this.props;
    clearAccountLookup();
    this.dismissModal();
    this.setPage('ACCOUNTLOOKUP');
  };

  renderPage = () => {
    const { page, email } = this.state;
    const {
      accountFound,
      auths,
      dismissError,
      onForgotPassword,
      loginFailed,
      registerError,
      enableMaestroLogin,
      customModal,
      marketingOptin,
      marketingOptinLabel,
    } = this.props;

    const validAuths = toAuthsArray(auths);

    switch (page) {
      case 'SIGNUP':
        return (
          <RegisterView
            customModal={customModal}
            dismissError={dismissError}
            email={email}
            enableMaestroLogin={enableMaestroLogin}
            id={CREATE_ACCOUNT_ID}
            marketingOptin={marketingOptin}
            marketingOptinLabel={marketingOptinLabel}
            onRegister={this.onRegister}
            registerError={registerError}
            validAuths={validAuths}
          />
        );
      case 'LOGIN':
        return (
          <LoginView
            accountFound={accountFound}
            customModal={customModal}
            dismissError={dismissError}
            enableMaestroLogin={enableMaestroLogin}
            id={ENTER_PASSWORD_ID}
            loginFailed={loginFailed}
            onForgotPassword={onForgotPassword}
            onLogin={this.onLogin}
            validAuths={validAuths}
          />
        );
      case 'GLOBAL_ACCOUNTS_SELECT_SITE':
        return <SelectSiteModal />;
      case 'ACCOUNTLOOKUP':
      default:
        return (
          <AccountLookupView
            accountFound={accountFound}
            enableMaestroLogin={enableMaestroLogin}
            id={ENTER_EMAIL_ID}
            onAccountLookup={this.onAccountLookup}
            setPage={this.setPage}
            validAuths={validAuths}
          />
        );
    }
  };

  render() {
    const { style } = this.props;
    const { page } = this.state;

    return (
      <StyledModalWindow
        className={MODAL_BACKGROUND}
        data-testid="authModal"
        id={AUTH_MODAL_ID}
        page={page}
        style={style}
      >
        <StyledCloseButton data-testid="authModalCloseButton" onClick={this.onClose} />
        <ContentContainer>
          <PageWrapper>{this.renderPage()}</PageWrapper>
        </ContentContainer>
      </StyledModalWindow>
    );
  }
}
